import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  appDarkBlue,
  appRed,
  navbarBlue,
  zindex,
} from "../../../../Design/SourcesJS/themes";
import BaseButton from "../../Buttons/BaseButton";
import { Box } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoIconDialog from "./InfoIconDialog";

export default function InfoDialog({
  text,
  open = false,
  handleClose = () => {},
  closeButtonOnly = false,
  chooseFunction = null,
  submitText = "Potvrdit",
  tooltip = null,
  error = null,
  dialog = undefined,
  closable = false,
}) {
  if (dialog) {
    text = dialog?.text || text;
    open = Boolean(dialog) || open;
    chooseFunction = dialog?.chooseFunction || chooseFunction;
    tooltip = dialog?.tooltip || tooltip;
    error = dialog?.error || error;
    submitText = dialog?.submitText || submitText;
    closable = dialog?.closable || closable;
    closeButtonOnly = dialog?.closeButtonOnly || closeButtonOnly;
  }

  if (!text) return <></>;
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        if (closeButtonOnly) return;
        handleClose(e);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: zindex.maxOverlay,
        ".MuiPaper-root": {
          borderRadius: "24px",
          minWidth: "280px",
          width: "410px",
          maxWidth: "430px",
          minHeight: "160px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          overflowX: "hidden",
        },
      }}
    >
      <DialogContent
        sx={[
          {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
            p: "32px",
          }, // mt: "15px"
          chooseFunction && {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          },
        ]}
      >
        {/*<Motion>
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 10,
              height: "20px",
              width: "20px",
            }}
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            <CloseRounded
              sx={{ fill: textGrey, height: "20px", width: "20px" }}
            />
          </Box>
          </Motion>*/}
        <DialogContentText
          sx={{
            fontSize: "18px",
            color: appDarkBlue,
            fontWeight: "bold",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {tooltip &&
            (tooltip === true ? (
              <InfoRounded sx={{ fill: navbarBlue, mb: "20px", mt: "5px" }} />
            ) : (
              <InfoIconDialog
                text={tooltip}
                sxButton={{
                  width: "38px",
                  height: "38px",
                  mb: "12px",
                }}
                sxPopper={{
                  zIndex: zindex.maxOverlay + 1,
                }}
              />
            ))}
          {error && !tooltip && (
            <CancelRoundedIcon sx={{ fill: appRed, mb: "20px", mt: "5px" }} />
          )}
          {text}
        </DialogContentText>
        {(chooseFunction || closable || closeButtonOnly) && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <BaseButton
              text={submitText || "Potvrdit"}
              onClick={(e) => {
                if (chooseFunction) {
                  chooseFunction();
                }
                handleClose();
              }}
              sxProps={{
                mx: "20px",
                textAlign: "center",
                mb: "0px",
                mt: "26px",
                maxWidth: "200px",
                bgcolor: navbarBlue,
              }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
