import React from "react";
import { default as MuiTooltip } from "@mui/material/Tooltip";

export default function Tooltip({
  children,
  text,
  delay = 100,
  enDelay = 200,
  placement = "top",
  ...props
}) {
  if (!text) return <>{children}</>;
  return (
    <>
      <MuiTooltip
        enterTouchDelay={delay}
        enterDelay={delay}
        enterNextDelay={enDelay}
        placement={placement}
        title={
          text ? <span style={{ fontSize: "13px" }}>{text}</span> : undefined
        }
        {...props}
      >
        {children}
      </MuiTooltip>
    </>
  );
}
