import * as React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { navbarBlue } from "../../../../Design/SourcesJS/themes";
import { Typography, styled } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(115, 117, 122, 0.97)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 230,
    borderRadius: "5px",
    margin: "5px",
    padding: "5px",
  },
}));

export default function InfoIconDialog({
  text = "",
  filled = true,
  iconColor = navbarBlue,
  placement = "top",
  sxIcon = {},
  sxButton = {},
  sxTooltip = {},
  sxPopper = {},
  open = undefined, // if true = force open
}) {
  return (
    <HtmlTooltip
      sx={{ ...sxTooltip }}
      open={open ? open : undefined}
      title={
        text ? (
          <React.Fragment>
            <div
              style={{
                padding: "10px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  color: "white",
                }}
              >
                {text}
              </Typography>
            </div>
          </React.Fragment>
        ) : undefined
      }
      placement={placement}
      PopperProps={{
        style: { ...sxPopper },
      }}
      enterTouchDelay={10}
      enterDelay={100}
      enterNextDelay={200}
    >
      <IconButton sx={{ p: "10px", ...sxButton }}>
        {filled ? (
          <InfoRoundedIcon sx={{ fill: iconColor, ...sxIcon }} />
        ) : (
          <InfoOutlinedIcon sx={{ fill: iconColor, ...sxIcon }} />
        )}
      </IconButton>
    </HtmlTooltip>
  );
}
