export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};
